.login {
    font-size: 35px;
    font-family: Lato;
    font-weight: 200;
    margin-Bottom: 40px
  }
.admin {
    font-size: 9px;
    font-family: Lato;
    font-weight: 900;
    color: #DA2C4C;
    margin-Bottom: 40px
  }

/* .grid{
    display: grid;
    place-items:  center;
} */
